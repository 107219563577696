.cutoff-text-enabled {
  --max-lines: 2;
  --line-height: 1.5;

  max-height: calc(var(--max-lines) * 1em * var(--line-height));
  line-height: var(--line-height);

  overflow: hidden;
  position: relative;
}

.cutoff-text-enabled:has(~ div > .expand-btn-h:not(:checked))::before {
  --future-rgba: rgba(23, 38, 53, var(--bg-opacity));

  content: '';
  position: absolute;
  height: calc(1em * var(--line-height));
  width: 100%;
  bottom: 0;
  pointer-events: none;
  background: linear-gradient(to bottom, transparent, var(--future-rgba));
}

.cutoff-text-enabled:has(~ div > .expand-btn-h:checked) {
  max-height: none;
}

.cutoff-text-disabled {
  --max-lines: 2;
  --line-height: 1.5;

  max-height: calc(var(--max-lines) * 1em * var(--line-height));
  line-height: var(--line-height);

  overflow: hidden;
  position: relative;
}

.cutoff-text-disabled:has(~ div > .expand-btn-h:not(:checked))::before {
  --past-rgba: rgba(34, 35, 38, var(--bg-opacity));

  content: '';
  position: absolute;
  height: calc(1em * var(--line-height));
  width: 100%;
  bottom: 0;
  pointer-events: none;
  background: linear-gradient(to bottom, transparent, var(--past-rgba));
}

.cutoff-text-disabled:has(~ div > .expand-btn-h:checked) {
  max-height: none;
}

.expand-btn-h {
  appearance: none;
}

.expand-btn-h::before {
  content: 'Expand';
}

.expand-btn-h:checked::before {
  content: 'Collapse';
}
