.barContainer {
  display: inline-block;
  cursor: pointer;
}

.barContainer:hover .bar {
  background-color: #5bc7b4;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 6px 0;
  transition: 0.4s;
}

.transition .bar1 {
  -webkit-transform: rotate(-45deg) translate(-6px, 6px);
  transform: rotate(-45deg) translate(-6px, 6px);
}

.transition .bar2 {
  opacity: 0;
}

.transition .bar3 {
  -webkit-transform: rotate(45deg) translate(-6px, -7px);
  transform: rotate(45deg) translate(-6px, -7px);
}

.notHidden {
  width: 100vw;
  height: 100vh;
  transition: all 0.5s ease;
}

.fadeToHidden {
  width: 0;
  height: 0;
  transition: all 0.5s ease;
}

.translate-x-0 {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  transition: all 0.5s ease;
}

.translate-x-full {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  transition: all 0.5s ease;
}

.nbCollapsedDrawer {
  height: 100vh;
}