
/* xl screen */
@media (min-width: 1280px) {
	.we-are-spacecamp {
		margin-right: 10%;
		padding-right: 10%;
	}
	.engage-with-us {
		padding-left: 9%;
		padding-right: 18%;
	}
	.connect-with-us {
		padding-left: 5%;
		margin-left: 5%;
	}
}

/* lg screen */
@media (min-width: 1024px) {
	.we-are-spacecamp {
		margin-right: 10%;
		padding-right: 10%;
	}
	.engage-with-us {
		padding-left: 9%;
		padding-right: 18%;
	}
	.connect-with-us {
		padding-left: 5%;
		margin-left: 5%;
	}
}

/* md screen */
@media (min-width: 768px) {
	.we-are-spacecamp {
		margin-right: 5%;
		padding-right: 5%;
	}
	.engage-with-us {
		padding-left: 4.8%;
		padding-right: 9%;
	}
	.connect-with-us {
		padding-left: 2%;
		margin-left: 5%;
	}
}

/* sm screen */
@media (min-width: 640px) {
	.we-are-spacecamp {
		margin-right: 5%;
		padding-right: 5%;
	}
	.engage-with-us {
		padding-left: 4.8%;
		padding-right: 9%;
	}
	.connect-with-us {
		padding-left: 2%;
		margin-left: 5%;
	}
}