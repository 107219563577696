.linkedInLink .LinkedInFooterIcon {
  fill: #fff;
}

.linkedInLink:hover .LinkedInFooterIcon {
  fill: #7fc4fd;
}

.youTubeIcon {
  transform: translate(0px,2px);
}