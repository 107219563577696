.cutoff-text-enabled {
  --max-lines: 3;
  --line-height: 1.5;

  max-height: calc(var(--max-lines) * 1em * var(--line-height));
  line-height: var(--line-height);

  overflow: hidden;
  position: relative;
}

.cutoff-text-enabled:has(~ div > .expand-btn-v:not(:checked))::before {
  --future-rgba: rgba(23, 38, 53, var(--bg-opacity));

  content: '';
  position: absolute;
  height: calc(1em * var(--line-height));
  width: 100%;
  bottom: 0;
  pointer-events: none;
  background: linear-gradient(to bottom, transparent, var(--future-rgba));
}

.cutoff-text-enabled:has(~ div > .expand-btn-v:checked) {
  max-height: none;
}

.cutoff-text-disabled {
  --max-lines: 3;
  --line-height: 1.5;

  max-height: calc(var(--max-lines) * 1em * var(--line-height));
  line-height: var(--line-height);

  overflow: hidden;
  position: relative;
}

.cutoff-text-disabled:has(~ div > .expand-btn-v:not(:checked))::before {
  --past-rgba: rgba(34, 35, 38, var(--bg-opacity));

  content: '';
  position: absolute;
  height: calc(1em * var(--line-height));
  width: 100%;
  bottom: 0;
  pointer-events: none;
  background: linear-gradient(to bottom, transparent, var(--past-rgba));
}

.cutoff-text-disabled:has(~ div > .expand-btn-v:checked) {
  max-height: none;
}

.expand-btn-v {
  appearance: none;
}

.expand-btn-v::before {
  content: 'Expand';
}

.expand-btn-v:checked::before {
  content: 'Collapse';
}

/* Without this the expand and collapse button goes of the right side of the page on a mobile device not sure why, this compensates for that */
.expand-btn-v:checked {
  margin-right: 4em;
}

/* Without this the expand and collapse button goes of the right side of the page on a mobile device not sure why, this compensates for that */
.expand-btn-v:not(:checked) {
  margin-right: 2.75em;
}
