.bg-disabled-dark-grey {
  --bg-opacity: 1;
  background-color: #222326;
  background-color: rgba(34, 35, 38, var(--bg-opacity));
}

.bg-disabled-light-grey {
  --bg-opacity: 1;
  background-color: #a4a5a8;
  background-color: rgba(164, 165, 168, var(--bg-opacity));
}

.text-disabled-light-grey {
  --text-opacity: 1;
  color: #a4a5a8;
  color: rgba(164, 165, 168, var(--text-opacity));
}

.horizontal-event-card-main {
  width: 826px;
  height: auto;
}

.horizontal-event-date-wrapper {
  width: 102px;
  height: 102px;
}

.horizontal-event-date-wrapper div.flex {
  width: 90px;
  height: 90px;
}

.vertical-event-card-main {
  width: 344px;
  height: auto;
}

.vertical-event-card-date-wrapper {
  width: 56px;
  height: 56px;
}

.vertical-event-card-date-wrapper div.flex {
  width: 48px;
  height: 48px;
}
