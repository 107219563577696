.banner-cover {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 80vh;
}

.banner-text-cover {
  background: rgba(3, 6, 14, 0.62) 0% 0% no-repeat;
}
