.partnerCard:hover .partnerName {
  color: #5bc7b4;
}

.partnerCarousel .slick-slide {
  transform: scale(0.8);
}

.partnerCarousel .slick-next:before {
  content: none;
}

.partnerCarousel .slick-prev:before {
  content: none;
}

.partnerCarousel .slick-prev .cls-1,
.partnerCarousel .slick-next .cls-1 {
  fill: #fff;
}

.partnerCarousel .slick-prev:hover .cls-1,
.partnerCarousel .slick-next:hover .cls-1 {
  fill: #5bc7b4;
}

@media (min-width: 0px) {
  .slick-arrow {
    transform: scale(1.5);
  }
}

@media (min-width: 768px) {
  .partnerCarousel .slick-arrow {
    transform: scale(1.5);
  }
  .initiativeCarousel .slick-next {
    right: -5px;
  }

  .initiativeCarousel .slick-prev {
    left: 0px;
  }
}

@media (min-width: 640px) {
  .partnerCarousel .slick-arrow {
    transform: scale(2);
  }
}

.partnerCarousel .slick-dots li button:before {
  color: white;
  transform: scale(2);
}

.partnerCarousel .slick-dots li.slick-active button:before {
  color: #5bc7b4 !important;
  transform: scale(2);
}

.partner-banner-main {
  background-image: url('../../assets/images/PartnersBanner.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

.partner-card-image {
  height: 180px;
  width: 180px;
}

.partner-with-us-link {
  width: 248px;
}