.projectCard .nextCardArrow .cls-1 {
  fill: #fff;
}

.projectCard .nextCardArrow:hover .cls-1 {
  fill: #5bc7b4;
}

.resp-container {
  position: relative;
  overflow: hidden;
  padding-top: 50%;
}

.resp-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
