.initiativeCarousel .slick-track {
  padding-top: 100px !important;
  padding-bottom: 75px !important;
}

.initiativeCarousel .slick-slide {
  transform: scale(0.7);
}

.initiativeCarousel .slick-slide.slick-active {
  transform: scale(0.8);
}

.initiativeCarousel .slick-current {
  animation: createBox 1s;
  animation-fill-mode: forwards;
}

.initiativeCarousel .slick-current .initiativeCarouselCardButton {
  display: block;
}

.initiativeCarousel .slick-next:before {
  content: none;
}

.initiativeCarousel .slick-prev:before {
  content: none;
}

@media (min-width: 0px) {
  .slick-arrow {
    transform: scale(1.5);
  }
}

@media (min-width: 768px) {
  .initiativeCarousel .slick-arrow {
    transform: scale(2);
  }
  .initiativeCarousel .slick-next {
    right: -5px;
  }

  .initiativeCarousel .slick-prev {
    left: 0px;
    z-index: 1;
  }
}

@keyframes createBox {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
}

.initiativeCarousel .slick-dots li button:before {
  color: white;
  transform: scale(2);
}

.initiativeCarousel .slick-dots li.slick-active button:before {
  color: #5bc7b4 !important;
  transform: scale(2);
}
